import BasicSection from 'components/BasicSection';
import Layout from 'components/Layout';
import ExternalLink from 'components/links/ExternalLink';
import { Link, PageProps } from 'gatsby';
import React from 'react';

const Contact: React.FC<PageProps> = ({ path }) => {
    return (
        <Layout title="Ente - Contact" path={path}>
            <BasicSection>
                <BasicSection.Header>
                    <BasicSection.Heading>Contact</BasicSection.Heading>
                </BasicSection.Header>

                <BasicSection.BodyElevated>
                    <div className="grid2x2-md-container pb-md-5">
                        <Support />
                        <Privacy />
                        <BugReports />
                        <Copyright />
                    </div>
                    <hr />
                    <Offices />
                </BasicSection.BodyElevated>
            </BasicSection>
        </Layout>
    );
};

export default Contact;

const Support: React.FC = () => {
    // Margins do not collapse in a flex context. Because of this, the h3 top
    // margin gets added onto the existing container's margin.
    //
    // As a workaround, disable the top margins of the h3 element in the columns
    // of the first row.
    return (
        <div className="grid2x2-md-item">
            <h3 className="mt-0">Support</h3>
            Something not working for you? Please write to{' '}
            <ExternalLink href="mailto:support@ente.io">
                support@ente.io
            </ExternalLink>
            .
        </div>
    );
};

const Privacy: React.FC = () => {
    return (
        <div className="grid2x2-md-item">
            <h3 className="mt-md-0">Privacy</h3>
            Enquiries and requests relating to our privacy & data policy should
            be sent to{' '}
            <ExternalLink href="mailto:privacy@ente.io">
                privacy@ente.io
            </ExternalLink>
            .
        </div>
    );
};

const BugReports: React.FC = () => {
    return (
        <div className="grid2x2-md-item">
            <h3>Report a bug</h3>
            Ente was made by humans and is therefore not perfect. If you run
            into a bug, please write to{' '}
            <ExternalLink href="mailto:bug@ente.io">bug@ente.io</ExternalLink>.
            The more detail you can provide, the better!
        </div>
    );
};

const Copyright: React.FC = () => {
    return (
        <div className="grid2x2-md-item">
            <h3>Copyright</h3>
            We take intellectual property rights seriously and enforce a robust
            takedown/repeat infringer policy. You can submit a takedown notice
            by writing to{' '}
            <ExternalLink href="mailto:copyright@ente.io">
                copyright@ente.io
            </ExternalLink>
            .
        </div>
    );
};

const Offices: React.FC = () => {
    return (
        <div className="pt-md-2">
            <p>
                We are a small <Link to="/about">team</Link>, and for many of us
                Ente is an all consuming passion. Because of such blurred
                boundaries between work and play, we work remotely: from
                shanties in Kerala, to cafés in Helsinki.
            </p>
            <p>
                Since we are making open source apps and building with
                continuous community feedback, effectively our{' '}
                <ExternalLink href="https://github.com/ente-io">
                    GitHub
                </ExternalLink>{' '}
                and our{' '}
                <ExternalLink href="https://ente.io/discord">
                    Discord
                </ExternalLink>{' '}
                are our offices.
            </p>
            <p>
                We also have physical offices so that we can get mail, and
                rendezvous.
            </p>
            <CorporateOffice />
            <OtherPlaces />
        </div>
    );
};

const CorporateOffice: React.FC = () => {
    return (
        <div>
            <h5>Corporate office</h5>
            1111B S Governors Ave #6032
            <br />
            Dover, DE 19904
            <br />
            USA
        </div>
    );
};

const OtherPlaces: React.FC = () => {
    return (
        <div>
            <h5>Other places you can reach us</h5>
            <div className="d-flex flex-column gap-3 flex-sm-row gap-sm-4">
                <div>
                    4th Fl, Janardhan Towers 133/2,
                    <br />
                    Residency Road, Bengaluru,
                    <br />
                    India
                </div>
                <div>
                    Roetersstraat 4 - I,
                    <br />
                    1018 WC, Amsterdam,
                    <br />
                    Netherlands
                </div>
            </div>
        </div>
    );
};
